import { select, Store } from '@ngrx/store';
import { State, User } from '@wam/shared';
import { Observable } from 'rxjs';
import { authenticationSelectors } from '@wam/authentication';
import { take } from 'rxjs/operators';

export class WithAuthService {
  constructor(protected store: Store<State>) {}

  protected withUser(): Observable<User> {
    return this.store.pipe(select(authenticationSelectors.getCurrentUser), take(1));
  }
}
